var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 mt-10", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-auto pt-0", attrs: { cols: "10" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl mb-30" },
                    [
                      _c("div", { staticClass: "card-header-padding" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-weight-bold text-h6 text-typo mb-0",
                          },
                          [_vm._v(" Install Guidance ")]
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "card-padding pt-0 font-size-root text-body",
                        },
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "pl-0" },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "text-body" },
                                    [
                                      _vm._v(
                                        "To start using this product you will need to install it with the following commands:"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("ol", [
                            _c("li", { staticClass: "mb-2" }, [
                              _vm._v(" Install "),
                              _c("strong", [_vm._v("NodeJS LTS")]),
                              _vm._v(" version from"),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "\n                    text-decoration-none text-primary text-body-2\n                    ls-0\n                    font-weight-600\n                    ms-1\n                  ",
                                  attrs: {
                                    href: "https://nodejs.org/en/",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("NodeJS Official Page")]
                              ),
                              _vm._v(". "),
                            ]),
                            _c("li", { staticClass: "mb-2" }, [
                              _vm._v(" Purchase the product from"),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "\n                    text-decoration-none text-primary text-body-2\n                    ls-0\n                    font-weight-600\n                    ms-1\n                  ",
                                  attrs: {
                                    href: "https://www.creative-tim.com/product/vuetify-material-dashboard-pro",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Creative Tim")]
                              ),
                              _vm._v(". "),
                            ]),
                            _c("li", { staticClass: "mb-2" }, [
                              _vm._v(
                                " Now you can go to your product through the terminal. "
                              ),
                            ]),
                            _c("li", { staticClass: "mb-2" }, [
                              _vm._v(" Once you are there please type the "),
                              _c("code", [_vm._v("npm install")]),
                              _vm._v(" command. "),
                            ]),
                            _c("li", { staticClass: "mb-2" }, [
                              _vm._v(
                                " When dependencies are installing it is possible that some "
                              ),
                              _c("code", [_vm._v("warnings")]),
                              _vm._v(" and "),
                              _c("code", [_vm._v("vulnerabilities")]),
                              _vm._v(
                                " to appear on your terminal. Those will "
                              ),
                              _c("strong", [_vm._v("NOT")]),
                              _vm._v(" affect your product. "),
                            ]),
                            _c("li", { staticClass: "mb-2" }, [
                              _vm._v(
                                " After the dependencies are installed you can now turn on the project by typing "
                              ),
                              _c("code", [_vm._v("npm run dev")]),
                              _vm._v(" command. "),
                            ]),
                            _c(
                              "li",
                              [
                                _c("br"),
                                _c("v-alert", { attrs: { color: "#37d5f2" } }, [
                                  _c("span", { staticClass: "text-white" }, [
                                    _vm._v(
                                      "NOTE: we have not used yarn in our development, but only npm, so, to avoid any issues provoked by yarn, we suggest you do the same. If, however you decide to use yarn, and at some point you have any issues, please try again (all of the above) using npm."
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c("li", [
                              _vm._v(
                                " If you have some questions or issues with the product please send us an email here on"
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "\n                    text-decoration-none text-primary text-body-2\n                    ls-0\n                    font-weight-600\n                    ms-1\n                  ",
                                  attrs: {
                                    href: "mailto:support@creative-tim.com",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("support@creative-tim.com")]
                              ),
                              _vm._v(". "),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }